var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"cta-mood"},[_c('div',{staticClass:"cta-mood__content-outer wrapper"},[_c('div',{staticClass:"cta-mood__content"},[_c('GTitle',{staticClass:"h1",attrs:{"content":_vm.content.title,"tag":"h2"}}),_c('div',{staticClass:"m-xxs",domProps:{"innerHTML":_vm._s(_vm.content.htmltext)}}),_c('GAction',{staticClass:"cta-mood__content__link",attrs:{"content":Object.assign({}, _vm.content.link, {modifiers: ['btn']})}})],1)]),_c('figure',{staticClass:"cta-mood__picture"},[_c('img',{directives:[{name:"src",rawName:"v-src",value:(_vm.content.picture),expression:"content.picture"}],attrs:{"sets":[
        '480',
        '640',
        '960',
        '1280',
        '1440',
        '1600',
        '1920',
        '2240',
        '2560',
        '2880' ],"sizes":"(min-width: 1024px) 50vw, 100vw"}})])])}
var staticRenderFns = []

export { render, staticRenderFns }